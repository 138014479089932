import { CurryApi } from '@spices/curry'
import config from './config'

// Models
import Nft from './nfts/model'
import Voucher from '../users/vouchers/model'
import { Embed } from '../embeds'
import NFTTransfer from './nft-transfers/model'
import SaylNFT from './sayl-nfts/model'
import NFTPeerTranfer from './nft-peer-transfers/model'

import { useNftStore } from './store'

/**
 * Controller class for the entity Nft
 * 
 * @class
 */
export default class NftController {
  /**
   * @constructor
   */
  constructor({ logger, store, transports }) {
    // UTILS
    this._api = new CurryApi({ config: config, transports })
    this._logger = logger

    this._store = store
    this._store.register('nft', useNftStore)
  }

  /////////////////////////////////////////
  ///           INIT
  /**
   * Initialise 
   * 
   * @param {Object} options
   */
  async init({ }) {
    try {
      return
    } catch(e) {
      throw e
    }
  }

  /////////////////////////////////////////
  ///           GETTERS

  get claimMeta() {
    return this._store.nft().claimMeta
  }

  /**
   * @property {String} intent
   * @readonly
   * @return {String}
   */
  get intent() {
    return this._store.nft().intent
  }

  /**
   * @property {Boolean} loading
   * @readonly
   * @return {Boolean}
   */
  get loading() {
    return this._store.nft().loading
  }

  /**
   * @property {Nft} nft
   * @readonly
   * @return {Nft}
   */
  get nft() {
    return this._store.nft().nft
  }

  /**
   * @property {NFTTransfer} transfer
   * @readonly
   * @return {NFTTransfer}
   */
  get transfer() {
    return this._store.nft().transfer
  }

  /**
   * @property {NFTTransfer} peerTransfer
   * @readonly
   * @return {NFTTransfer}
   */
  get peerTransfer() {
    return this._store.nft().peerTransfer
  }

  /**
   * @property {Array} vouchers
   * @readonly
   * @return {Array<Voucher>}
   */
  get vouchers() {
    return this._store.nft().vouchers
  }

  /////////////////////////////////////////
  ///           METHODS  
  /**
   * @param {Object} options 
   * @param {String} options.id
   */
  async view({ id }) {
    try {
      this._store.nft().loading = true
      this._store.nft().nft = null
      
      let { data } = await this._api.get({ type: 'entity', payload: { id } }) 
      let nftTransfer = new NFTTransfer(data.nft_transfer)

      let nft = null

      if(nftTransfer.status === 10) {
        nft = new SaylNFT(data.nft)
      } else {
        nft = new Nft(data.nft)
      }

      if(data.intent != null) {
        this._store.nft().intent = data.intent
      }

      if(nftTransfer.nft != null && nftTransfer.nft.embedId != null) {
        let embed = await this.getEmbed({ id: nftTransfer.nft.embedId })
        nft.embed = embed
      }

      if(nft.embed != null && nft.embed.customCss != null) {
        let style = document.createElement('style')
        style.setAttribute('name', 'claim_style')
        style.setAttribute('type', 'text/css')
        style.textContent = nft.embed.customCss
        document.head.appendChild(style)
      }

      this._store.nft().nft = nft
      this._store.nft().transfer = nftTransfer
      return this._store.nft().nft
    } catch(e) {
      throw e
    } finally {
      this._store.nft().loading = false
    }
  }

  async claim(item) {
    try {
      this._store.nft().loading = true

      let { data } = await this._api.post({ type: 'entity', payload: { item }})
      return data
    } catch(e) {
      throw e
    } finally {
      this._store.nft().loading = false
    }
  }

  async getEmbed(args = { id: null }) {
    try {
      let { data } = await this._api.get({ type: 'embed', payload: args })
      return new Embed(data)
    } catch(e) {
      throw e
    }
  }

  async getNftVouchers(args = {}) {
    try {
      let { data } = await this._api.get({ type: 'vouchers', payload: args })
      this._store.nft().vouchers = data && data.length > 0 ? data.map(d => new Voucher(Object.assign(d, { web3: true }))) : null
      return this._store.nft().vouchers
    } catch(e) {
      throw e
    }
  }

  /////////////////////////////////////////
  //        Self claim
  async viewSelfClaim(args = { id }) {
    try {
      let { data } = await this._api.get({ type: 'selfClaim', payload: args })
      
      let nft = data && data.nft ? new SaylNFT(data.nft) : null

      if(nft != null && nft.embedId != null) {
        let embed = await this.getEmbed({ id: nft.embedId })
        nft.embed = embed
      }

      if(nft.embed != null && nft.embed.customCss != null) {
        let style = document.createElement('style')
        style.setAttribute('name', 'claim_style')
        style.setAttribute('type', 'text/css')
        style.textContent = nft.embed.customCss
        document.head.appendChild(style)
      }

      this._store.nft().claimMeta = {
        isMinted: data.is_minted || 0,
        isOngoing: data.is_ongoind || 0,
        selfClaim: data.self_claims || 0,
      }

      this._store.nft().nft = nft
      return this._store.nft().nft
    } catch(e) {
      throw e
    }
  }

  async selfClaim(item) {
    try {
      this._store.nft().loading = true

      let { data } = await this._api.post({ type: 'selfClaim', payload: { item }})
      return data
    } catch(e) {
      throw e
    } finally {
      this._store.nft().loading = false
    }
  }

  /////////////////////////////////////////
  //        Transfer
  async sendPeerTransfer(payload = { address: null, item: { destination: null }, serial: null }) {
    try {
      let { data } = await this._api.post({ type: 'transfer', payload })
      return data
    } catch(e) {
      throw e
    }
  }

  async viewPeerTransfer(payload = { id: null }) {
    try {
      this._store.nft().loading = true
      this._store.nft().nft = null

      let { data } = await this._api.get({ type: 'transfer', payload })
      let nftTransfer = new NFTPeerTranfer(data.nft_peer_transfer)
      
      let nft = null

      if(nftTransfer.status === 10) {
        nft = new SaylNFT(data.nft)
      } else {
        nft = new Nft(data.nft)
      }

      // if(nftTransfer.nft != null && nftTransfer.nft.embedId != null) {
      //   let embed = await this.getEmbed({ id: nftTransfer.nft.embedId })
      //   nft.embed = embed
      // }

      // if(nft.embed != null && nft.embed.customCss != null) {
      //   let style = document.createElement('style')
      //   style.setAttribute('name', 'claim_style')
      //   style.setAttribute('type', 'text/css')
      //   style.textContent = nft.embed.customCss
      //   document.head.appendChild(style)
      // }

      if(data.intent != null) {
        this._store.nft().intent = data.intent
      }

      this._store.nft().nft = nft
      this._store.nft().peerTransfer = nftTransfer

      return data
    } catch(e) {
      throw e
    } finally {
      this._store.nft().loading = false
    }
  }

  async claimPeerTransfer(item) {
    try {
      this._store.nft().loading = true

      let { data } = await this._api.post({ type: 'confirmTransfer', payload: { item }})
      return data
    } catch(e) {
      throw e
    } finally {
      this._store.nft().loading = false
    }
  }

  async cancelPeerTransfer(payload = { id }) {
    try {
      await this._api.delete({ type: 'transfer', payload })
      return
    } catch(e) {
      throw e
    }
  }

  async signForPeerTransfer(payload = { item: { pin: null }, token: null}) {
    try {
      await this._api.post({ type: 'signPeerTransfer', payload})
      return
    } catch(e) {
      throw e
    }
  }
}
